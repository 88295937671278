import { Component } from 'react';
import './SignUp.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faLock,
	faUser,
	faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Button from 'Components/shared/Button/Button';
import Input from 'Components/shared/Input/Input';
import Form from 'Components/shared/Form/Form';
import Label from 'Components/shared/Label/Label';
import firebase from 'modules/web/firebase';
import { setCookie } from 'modules/cookies';

class SignUp extends Component {
	constructor() {
		super();

		this.state = {};
	}

	async submit() {
		const { firstName, lastName, email, password, confirmPassword } =
			this.state;

		if (
			!firstName ||
			!lastName ||
			!email ||
			!password ||
			!confirmPassword
		) {
			this.setState({
				submitted: true,
				error: 'Missing fields',
			});

			return;
		}

		if (password !== confirmPassword) {
			this.setState({
				submitted: true,
				error: 'Passwords do not match',
			});

			return;
		}

		this.setState({
			loading: true,
		});

		const user = await firebase.createUser({
			firstName,
			lastName,
			email,
			password,
		});

		if (!user) {
			this.setState({
				loading: false,
				error: 'An error occurred',
			});

			return;
		}

		setCookie('email', email);
		setCookie('password', password);

		this.setState(
			{
				loading: false,
			},
			() => {
				window.location = '/';
			}
		);
	}

	render() {
		const { submitted, error, loading } = this.state;

		return (
			<Form>
				<div className='form'>
					<div className='header'>
						<Label
							icon='fa-user-plus'
							label='Ready to get started?'
						/>
						<span className='subtitle'>
							We need some information to begin.
						</span>
					</div>
					<div className='inputs'>
						<div className='section'>
							<Input
								label='First Name'
								placeholder='Enter your first name'
								submitted={submitted}
								required
								onChange={(firstName) => {
									this.setState({
										firstName,
									});
								}}
							/>
							<Input
								label='Last Name'
								placeholder='Enter your last name'
								submitted={submitted}
								required
								onChange={(lastName) => {
									this.setState({
										lastName,
									});
								}}
							/>
						</div>
						<Input
							label='Email'
							placeholder='Enter your email'
							onChange={(email) => {
								this.setState({
									email,
								});
							}}
							submitted={submitted}
							required
						/>
						<Input
							label='Password'
							placeholder='Enter a password'
							type='password'
							submitted={submitted}
							required
							onChange={(password) => {
								this.setState({
									password,
								});
							}}
						/>
						<Input
							label='Confirm Password'
							placeholder='Enter the password again'
							submitted={submitted}
							error={error === 'Passwords do not match'}
							type='password'
							required
							onChange={(confirmPassword) => {
								this.setState({
									confirmPassword,
								});
							}}
						/>
						{error && <span className='error'>{error}</span>}
					</div>
					<div className='controls'>
						{loading && (
							<i className='fa fa-spinner-third fa-spin' />
						)}
						<div className='buttons'>
							<Button
								label='Submit'
								onClick={this.submit.bind(this)}
								cta
							/>
							<Link to='/login'>
								<Button label='Login' transparent />
							</Link>
						</div>
					</div>
				</div>
			</Form>
		);
	}
}

export default SignUp;
