import Label from 'Components/shared/Label/Label';
import './Pricing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowRight,
	faCheck,
	faCheckCircle,
	faCircleArrowDown,
	faCircleArrowUp,
	faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'Components/shared/Button/Button';
import { Gradient } from 'react-gradient';
import { Component } from 'react';

const gradients = [
	['#AC9EFA', '#6F57F7'],
	['#4729F5', '#EE4266'],
];

class Pricing extends Component {
	constructor({ selected }) {
		super();

		this.state = {
			selected,
		};
	}

	render() {
		return (
			<div className='pricing'>
				<Label label='Pricing' icon='fa-dollar-sign' />
				<span className='subtitle'>Plans to match your needs.</span>
				<div className='pricing-content'>
					<div className='pricing-card'>
						<div className='card-content'>
							<div className='header'>
								<span className='tier'>Free</span>
							</div>
							<div className='body'>
								<div className='body-header'>
									<span className='price price-dark'>$0</span>
								</div>
								<div className='features'>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Personalized AI Chat
										</span>
									</div>
									{/* <div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized Profile
											</span>
										</div> */}
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCircleArrowDown}
											className='no-feature-icon'
										/>
										<span className='no-feature-text'>
											Limited Conversations
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faXmarkCircle}
											className='no-feature-icon'
										/>
										<span className='no-feature-text'>
											Response Rewriter
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faXmarkCircle}
											className='no-feature-icon'
										/>
										<span className='no-feature-text'>
											Shared Responses
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faXmarkCircle}
											className='no-feature-icon'
										/>
										<span className='no-feature-text'>
											Long Term Memory
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faXmarkCircle}
											className='no-feature-icon'
										/>
										<span className='no-feature-text'>
											ChatGPT 4.0
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='pricing-card popular'>
						<div className='card-content'>
							<div className='header'>
								<span className='tier'>Pro</span>
							</div>
							<div className='body'>
								<div className='body-header'>
									<Gradient
										gradients={gradients}
										angle='90deg'
										property='text'
										element='span'
										className='price'>
										$10
									</Gradient>
									<span className='price-label'>
										/ monthly
									</span>
								</div>
								<div className='features'>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Personalized AI Chat
										</span>
									</div>
									{/* <div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized Profile
											</span>
										</div> */}
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCircleArrowUp}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Unlimited Conversations
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Response Rewriter
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Shared Responses
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faXmarkCircle}
											className='no-feature-icon'
										/>
										<span className='no-feature-text'>
											Long Term Memory
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faXmarkCircle}
											className='no-feature-icon'
										/>
										<span className='no-feature-text'>
											ChatGPT 4.0
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='pricing-card'>
						<div className='card-content'>
							<div className='header'>
								<span className='tier'>Unlimited</span>
							</div>
							<div className='body'>
								<div className='body-header'>
									<Gradient
										gradients={gradients}
										angle='90deg'
										property='text'
										element='span'
										className='price'>
										$20
									</Gradient>
									<span className='price-label'>
										/ monthly
									</span>
								</div>
								<div className='features'>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Personalized AI Chat
										</span>
									</div>
									{/* <div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized Profile
											</span>
										</div> */}
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCircleArrowUp}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Unlimited Conversations
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Response Rewriter
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Shared Responses
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											Long Term Memory
										</span>
									</div>
									<div className='feature'>
										<FontAwesomeIcon
											icon={faCheckCircle}
											className='has-feature-icon'
										/>
										<span className='feature-text'>
											ChatGPT 4.0
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Pricing;
