import { Component } from 'react';
import './Landing.scss';
import ThreeDHero from './3DHero/3DHero';
import { Gradient } from 'react-gradient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowDown,
	faArrowRight,
	faCheckCircle,
	faCircle,
	faCircleArrowDown,
	faCircleArrowUp,
	faCircleUp,
	faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'Components/shared/Button/Button';
import Label from 'Components/shared/Label/Label';
import { Link } from 'react-router-dom';

const heroGradients = [
	['#ff0000', '#0000ff'],
	['#00ff00', '#ff0000'],
];

const gradients = [
	['#AC9EFA', '#6F57F7'],
	['#4729F5', '#EE4266'],
];

class Landing extends Component {
	render() {
		return (
			<div className='landing'>
				<div className='sections'>
					<div className='section'>
						<ThreeDHero />
						<div className='section'>
							<h1 className='hero-title'>
								A More{' '}
								<Gradient
									gradients={heroGradients}
									angle='90deg'
									property='text'
									element='h1'>
									Personalized
								</Gradient>{' '}
								AI Experience.
							</h1>
						</div>
					</div>
					<div className='section'>
						<span className='subtitle'>
							ThoughtLink gives you the power to personalize your
							AI interactions and share your best experiences.
						</span>
					</div>
				</div>
				<Label
					label='Pricing'
					className='pricing-label'
					icon='fa-dollar-sign'
				/>
				<div className='section'>
					<div className='pricing-section'>
						<div className='pricing-card'>
							<div className='card-content'>
								<div className='header'>
									<span className='tier'>Free</span>
								</div>
								<div className='body'>
									<div className='body-header'>
										<span className='price price-dark'>
											$0
										</span>
									</div>
									<div className='features'>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized AI Chat
											</span>
										</div>
										{/* <div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized Profile
											</span>
										</div> */}
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCircleArrowDown}
												className='no-feature-icon'
											/>
											<span className='no-feature-text'>
												Limited Conversations
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faXmarkCircle}
												className='no-feature-icon'
											/>
											<span className='no-feature-text'>
												Response Rewriter
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faXmarkCircle}
												className='no-feature-icon'
											/>
											<span className='no-feature-text'>
												Shared Responses
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faXmarkCircle}
												className='no-feature-icon'
											/>
											<span className='no-feature-text'>
												Long Term Memory
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faXmarkCircle}
												className='no-feature-icon'
											/>
											<span className='no-feature-text'>
												ChatGPT 4.0
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='buttons'>
								<Link to='/sign-up' className='link'>
									<Button
										label='Get Started'
										rightIcon={faArrowRight}
									/>
								</Link>
							</div>
						</div>
						<div className='pricing-card popular'>
							<div className='card-content'>
								<div className='header'>
									<span className='tier'>Pro</span>
								</div>
								<div className='body'>
									<div className='body-header'>
										<Gradient
											gradients={gradients}
											angle='90deg'
											property='text'
											element='span'
											className='price'>
											$10
										</Gradient>
										<span className='price-label'>
											/ monthly
										</span>
									</div>
									<div className='features'>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized AI Chat
											</span>
										</div>
										{/* <div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized Profile
											</span>
										</div> */}
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCircleArrowUp}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Unlimited Conversations
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Response Rewriter
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Shared Responses
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faXmarkCircle}
												className='no-feature-icon'
											/>
											<span className='no-feature-text'>
												Long Term Memory
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faXmarkCircle}
												className='no-feature-icon'
											/>
											<span className='no-feature-text'>
												ChatGPT 4.0
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='buttons'>
								<Link to='/sign-up' className='link'>
									<Button
										inverted
										label='Get Started'
										rightIcon={faArrowRight}
									/>
								</Link>
							</div>
						</div>
						<div className='pricing-card'>
							<div className='card-content'>
								<div className='header'>
									<span className='tier'>Unlimited</span>
								</div>
								<div className='body'>
									<div className='body-header'>
										<Gradient
											gradients={gradients}
											angle='90deg'
											property='text'
											element='span'
											className='price'>
											$20
										</Gradient>
										<span className='price-label'>
											/ monthly
										</span>
									</div>
									<div className='features'>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized AI Chat
											</span>
										</div>
										{/* <div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Personalized Profile
											</span>
										</div> */}
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCircleArrowUp}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Unlimited Conversations
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Response Rewriter
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Shared Responses
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												Long Term Memory
											</span>
										</div>
										<div className='feature'>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='has-feature-icon'
											/>
											<span className='feature-text'>
												ChatGPT 4.0
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='buttons'>
								<Link to='/sign-up' className='link'>
									<Button
										label='Get Started'
										rightIcon={faArrowRight}
									/>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className='section'>
					<div className='caption-container'>
						<div className='captions'>
							<span className='caption'>Want to know more?</span>
							<span className='subcaption'>
								or{' '}
								<Link className='get-started' to='/sign-up'>
									get started
								</Link>
							</span>
						</div>
						<Link to='/about'>
							<FontAwesomeIcon
								icon={faArrowDown}
								className='arrow'
							/>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default Landing;
