import axios from 'axios';

const baseUrl = 'https://us-central1-thoughtlink-app.cloudfunctions.net/app';

async function rewriteText({ text }) {
	try {
		const response = await axios.post(`${baseUrl}/rewrite`, {
			text,
		});

		return response.data;
	} catch (err) {
		return;
	}
}

export { rewriteText };
