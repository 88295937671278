import { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from 'Components/pages/Landing/Landing';
import Footer from 'Components/containers/Footer/Footer';
import Navbar from 'Components/containers/Navbar/Navbar';
import './App.scss';
import SignUp from 'Components/pages/SignUp/SignUp';
import Login from 'Components/pages/Login/Login';
import Survey from 'Components/pages/Survey/Survey';
import Payment from 'Components/pages/Payment';
import Pricing from 'Components/pages/Pricing';
import Chat from 'Components/pages/User/Chat/Chat';
import Profile from 'Components/pages/Profile/Profile';
import firebase from 'modules/web/firebase';
import { getCookie, setCookie } from 'modules/cookies';
import AvatarEditor from 'Components/pages/AvatarEditor/AvatarEditor';
import Upgrade from 'Components/pages/Upgrade';
import Blog from 'Components/pages/Blog/Blog';
import About from 'Components/pages/About/About';
import Settings from 'Components/pages/Settings/Settings';

class App extends Component {
	constructor() {
		super();

		this.state = {
			loggedIn: false,
			completedSetup: false,
			loaded: false,
			theme: getCookie('theme') ?? 'dark',
		};
	}

	updateTheme() {
		const theme = this.state.theme;

		if (theme === 'dark') {
			document.documentElement.setAttribute('data-theme', 'dark');
		} else {
			document.documentElement.setAttribute('data-theme', 'light');
		}
	}

	setTheme(theme) {
		this.setState(
			{
				theme,
			},
			() => {
				this.updateTheme();
			}
		);

		setCookie('theme', theme);
	}

	async componentDidMount() {
		this.updateTheme();

		const email = getCookie('email');
		const password = getCookie('password');

		if (!email || !password) {
			this.setState({
				loggedIn: false,
				completedSetup: false,
				loaded: true,
			});

			return;
		}

		const user = await firebase.loginUser({ password, email });

		if (!user) {
			this.setState({
				loggedIn: false,
				completedSetup: false,
				loaded: true,
			});

			return;
		}

		const completedSetup = user.completedSetup;

		this.setState({
			loaded: true,
			completedSetup,
			loggedIn: true,
			user,
		});
	}

	toggleSettings() {
		this.setState({
			showSettings: !this.state.showSettings,
		});
	}

	closeSettings() {
		this.setState({
			showSettings: false,
		});
	}

	render() {
		const { loggedIn, user, loaded, completedSetup, showSettings, theme } =
			this.state;

		return (
			loaded && (
				<BrowserRouter>
					{showSettings && (
						<Settings
							user={user}
							closeSettings={this.closeSettings.bind(this)}
							setTheme={this.setTheme.bind(this)}
							theme={theme}
						/>
					)}
					<Navbar
						loggedIn={loggedIn}
						setTheme={this.setTheme.bind(this)}
						completedSetup={completedSetup}
						theme={theme}
						toggleSettings={this.toggleSettings.bind(this)}
					/>
					<Routes>
						{!loggedIn ? (
							<>
								<Route path='*' element={<Landing />} />
								<Route path='/sign-up' element={<SignUp />} />
								<Route path='/login' element={<Login />} />
								<Route path='/pricing' element={<Pricing />} />
								<Route path='/blog' element={<Blog />} />
								<Route path='/about' element={<About />} />
								<Route
									path='/payment'
									element={
										<Payment
											paymentDetails={{
												plan: 'Pro',
												price: 10,
											}}
										/>
									}
								/>
							</>
						) : completedSetup ? (
							<>
								<Route
									path='*'
									element={<Chat user={user} />}
								/>
								<Route path='/blog' element={<Blog />} />
								<Route
									path='/upgrade'
									element={<Upgrade user={user} />}
								/>
								<Route
									path='/survey'
									element={<Survey user={user} />}
								/>
								<Route
									path='/payment/:tier'
									element={<Payment user={user} />}
								/>
								<Route
									path='/chat'
									element={<Chat user={user} />}
								/>
								<Route path='/profile' element={<Profile />} />
								<Route
									path='/avatar-editor'
									element={<AvatarEditor />}
								/>
							</>
						) : (
							<>
								<Route
									path='*'
									element={<Survey user={user} />}
								/>
							</>
						)}
					</Routes>
					<Footer />
				</BrowserRouter>
			)
		);
	}
}

export default App;
