import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Button.scss';

function Button({
	label,
	transparent,
	inverted,
	disabled,
	cta,
	icon,
	rightIcon,
	big,
	danger,
	className,
	...rest
}) {
	return (
		<button
			className={`button ${big && 'button-big'} button-${
				transparent
					? 'transparent'
					: inverted
					? 'inverted'
					: cta
					? 'cta'
					: disabled
					? 'disabled'
					: danger
					? 'danger'
					: 'default'
			} ${className}`}
			{...rest}>
			{icon && <FontAwesomeIcon icon={icon} />}
			{label}
			{rightIcon && <FontAwesomeIcon icon={rightIcon} />}
		</button>
	);
}

export default Button;
