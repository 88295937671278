import axios from 'axios';

const baseUrl = window.location.href.includes('localhost')
	? 'http://localhost:5000/thoughtlink-app/us-central1/app'
	: 'https://us-central1-thoughtlink-app.cloudfunctions.net/app';

async function promptChatGPT({ messages, email, password }) {
	try {
		const response = await axios.post(`${baseUrl}/chatgpt`, {
			messages,
			email,
			password,
		});

		return response.data;
	} catch (err) {
		console.error(err);

		return;
	}
}

async function getSimilarAnswers({ message, email, password }) {
	try {
		const response = await axios.post(`${baseUrl}/similar-answers`, {
			message,
			email,
			password,
		});

		return response.data;
	} catch (err) {
		console.error(err);

		return;
	}
}

async function streamChatGPT({ messages, email, password, onChunk }) {
	try {
		const response = await fetch(`${baseUrl}/stream-chatgpt`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ messages, email, password }),
		});

		if (!response.ok) {
			return;
		}

		const reader = response.body.getReader();
		const chunks = [];

		let done, value;

		while (!done) {
			({ value, done } = await reader.read());

			if (done) {
				return chunks;
			}

			// uint8array to string
			const content = String.fromCharCode.apply(null, value);

			chunks.push(content);
			onChunk(content);
		}
	} catch (err) {
		console.error(err);

		return false;
	}
}

export default {
	promptChatGPT,
	streamChatGPT,
	getSimilarAnswers,
};
