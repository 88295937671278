import { Component, createRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import './3DHero.scss';

class ThreeDHero extends Component {
	constructor() {
		super();

		this.containerRef = createRef();
	}

	componentDidMount() {
		const container = this.containerRef.current;

		if (!container) return;

		const scene = new THREE.Scene();

		const camera = new THREE.PerspectiveCamera(
			75,
			container.clientWidth / container.clientHeight,
			0.1,
			1000
		);
		camera.position.z = 2;
		camera.position.y = 0.5;

		const renderer = new THREE.WebGLRenderer({
			antialias: true,
			alpha: true,
		});

		renderer.setClearColor(0x000000, 0);
		container.appendChild(renderer.domElement);

		function resize() {
			const width = container.clientWidth;
			const height = window.innerHeight * 0.2;

			camera.aspect = width / height;
			camera.updateProjectionMatrix();

			renderer.setPixelRatio(window.devicePixelRatio);
			renderer.setSize(width, height);
		}

		resize();
		window.addEventListener('resize', resize);

		const loader = new GLTFLoader();

		loader.load('assets/models/userIcon.glb', (gltf) => {
			let model = gltf.scene;

			const material = new THREE.MeshPhysicalMaterial({
				color: 0x000000,
				reflectivity: 0.5,
				transparent: true,
				opacity: 0.4,
				roughness: 1,
			});

			model.traverse((o) => {
				if (o.isMesh) o.material = material;
			});

			model.position.y = -0.5;

			scene.add(model);
		});

		const light2 = new THREE.DirectionalLight(0xffffff, 1);
		light2.position.set(0, 1, 1);
		light2.lookAt(0, 0, 0);
		scene.add(light2);

		const light3 = new THREE.DirectionalLight(0xff0000, 4);
		light3.position.set(0, 1, 0);
		light3.rotation.y = 1;
		scene.add(light3);

		const light4 = new THREE.DirectionalLight(0xaa00ff, 4);
		light3.position.set(1, 0, 0);
		light3.rotation.y = 1;
		scene.add(light4);

		const gradientSphere = new THREE.SphereGeometry(0.5, 32, 32);
		const gradientMaterial = new THREE.ShaderMaterial({
			transparent: true,
			uniforms: {
				uTime: { value: 0 },
				uAlpha: { value: 0.8 },
			},
			vertexShader: `
                varying vec2 vUv;
                uniform float uTime;
                varying vec3 vNormal;

                vec3 mod289(vec3 x)
                {
                return x - floor(x * (1.0 / 289.0)) * 289.0;
                }

                vec4 mod289(vec4 x)
                {
                return x - floor(x * (1.0 / 289.0)) * 289.0;
                }

                vec4 permute(vec4 x)
                {
                return mod289(((x*34.0)+10.0)*x);
                }

                vec4 taylorInvSqrt(vec4 r)
                {
                return 1.79284291400159 - 0.85373472095314 * r;
                }

                vec3 fade(vec3 t) {
                return t*t*t*(t*(t*6.0-15.0)+10.0);
                }

                // Classic Perlin noise
                float cnoise(vec3 P)
                {
                vec3 Pi0 = floor(P); // Integer part for indexing
                vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
                Pi0 = mod289(Pi0);
                Pi1 = mod289(Pi1);
                vec3 Pf0 = fract(P); // Fractional part for interpolation
                vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
                vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
                vec4 iy = vec4(Pi0.yy, Pi1.yy);
                vec4 iz0 = Pi0.zzzz;
                vec4 iz1 = Pi1.zzzz;

                vec4 ixy = permute(permute(ix) + iy);
                vec4 ixy0 = permute(ixy + iz0);
                vec4 ixy1 = permute(ixy + iz1);

                vec4 gx0 = ixy0 * (1.0 / 7.0);
                vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
                gx0 = fract(gx0);
                vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
                vec4 sz0 = step(gz0, vec4(0.0));
                gx0 -= sz0 * (step(0.0, gx0) - 0.5);
                gy0 -= sz0 * (step(0.0, gy0) - 0.5);

                vec4 gx1 = ixy1 * (1.0 / 7.0);
                vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
                gx1 = fract(gx1);
                vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
                vec4 sz1 = step(gz1, vec4(0.0));
                gx1 -= sz1 * (step(0.0, gx1) - 0.5);
                gy1 -= sz1 * (step(0.0, gy1) - 0.5);

                vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
                vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
                vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
                vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
                vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
                vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
                vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
                vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

                vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
                g000 *= norm0.x;
                g010 *= norm0.y;
                g100 *= norm0.z;
                g110 *= norm0.w;
                vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
                g001 *= norm1.x;
                g011 *= norm1.y;
                g101 *= norm1.z;
                g111 *= norm1.w;

                float n000 = dot(g000, Pf0);
                float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
                float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
                float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
                float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
                float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
                float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
                float n111 = dot(g111, Pf1);

                vec3 fade_xyz = fade(Pf0);
                vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
                vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
                float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
                return 2.2 * n_xyz;
                }

                // Classic Perlin noise, periodic variant
                float pnoise(vec3 P, vec3 rep)
                {
                vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
                vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
                Pi0 = mod289(Pi0);
                Pi1 = mod289(Pi1);
                vec3 Pf0 = fract(P); // Fractional part for interpolation
                vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
                vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
                vec4 iy = vec4(Pi0.yy, Pi1.yy);
                vec4 iz0 = Pi0.zzzz;
                vec4 iz1 = Pi1.zzzz;

                vec4 ixy = permute(permute(ix) + iy);
                vec4 ixy0 = permute(ixy + iz0);
                vec4 ixy1 = permute(ixy + iz1);

                vec4 gx0 = ixy0 * (1.0 / 7.0);
                vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
                gx0 = fract(gx0);
                vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
                vec4 sz0 = step(gz0, vec4(0.0));
                gx0 -= sz0 * (step(0.0, gx0) - 0.5);
                gy0 -= sz0 * (step(0.0, gy0) - 0.5);

                vec4 gx1 = ixy1 * (1.0 / 7.0);
                vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
                gx1 = fract(gx1);
                vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
                vec4 sz1 = step(gz1, vec4(0.0));
                gx1 -= sz1 * (step(0.0, gx1) - 0.5);
                gy1 -= sz1 * (step(0.0, gy1) - 0.5);

                vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
                vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
                vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
                vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
                vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
                vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
                vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
                vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

                vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
                g000 *= norm0.x;
                g010 *= norm0.y;
                g100 *= norm0.z;
                g110 *= norm0.w;
                vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
                g001 *= norm1.x;
                g011 *= norm1.y;
                g101 *= norm1.z;
                g111 *= norm1.w;

                float n000 = dot(g000, Pf0);
                float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
                float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
                float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
                float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
                float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
                float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
                float n111 = dot(g111, Pf1);

                vec3 fade_xyz = fade(Pf0);
                vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
                vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
                float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
                return 2.2 * n_xyz;
                }

                void main() {
                    float distortion = pnoise((normal + uTime), vec3(5.0)) * 0.2;
                    vec3 newPosition = position + (normal * distortion);
                        
                    vNormal = normal;

                    gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
                    vUv = uv;
                }
            `,
			fragmentShader: `
                varying vec2 vUv;
                uniform float uTime;
                varying vec3 vNormal;
                uniform float uAlpha;

                void main() {
                    vec2 uv = vUv;
                    uv -= 0.5;
                    uv *= 2.0;
                    float len = length(uv);
                    vec3 color = vec3(0.0);
                    color.r = sin(len * 3.1415 + uTime);
                    color.g = sin(len * 3.1415 + 2.094 + uTime);
                    color.b = sin(len * 3.1415 + 4.188 + uTime);
                    gl_FragColor = vec4(color, uAlpha);
                }
            `,
		});

		const gradientMesh = new THREE.Mesh(gradientSphere, gradientMaterial);
		gradientMesh.position.set(0, 1.0, 0);
		scene.add(gradientMesh);

		const gradientMeshBackground = gradientMesh.clone();
		gradientMeshBackground.scale.set(1.2, 1.2, 1.2);
		gradientMeshBackground.material = gradientMaterial.clone();
		gradientMeshBackground.material.uniforms.uAlpha.value = 0.2;
		scene.add(gradientMeshBackground);

		function animate(t = 0) {
			requestAnimationFrame(animate);

			gradientMaterial.uniforms.uTime.value = t / 1000;
			gradientMeshBackground.material.uniforms.uTime.value = t / 1000;
			gradientMeshBackground.rotation.y += 0.01;

			renderer.render(scene, camera);
		}

		animate();
	}

	render() {
		return (
			<div className='threed-hero-container' ref={this.containerRef} />
		);
	}
}

export default ThreeDHero;
