import { Component } from 'react';
import { Gradient } from 'react-gradient';
import './Blog.scss';
import Button from 'Components/shared/Button/Button';
import Label from 'Components/shared/Label/Label';

const blogPosts = [
	{
		title: 'Test title',
		description: 'Test description',
		content: '__Test content__\n- hello world\n- test123',
		date: 'June 12, 2021',
	},
	{
		title: 'Test title',
		description: 'Test description',
		content: '__Test content__\n- hello world\n- test123',
		date: 'June 12, 2021',
	},
	{
		title: 'Test title',
		description: 'Test description',
		content: '__Test content__\n- hello world\n- test123',
		date: 'June 12, 2021',
	},
	{
		title: 'Test title',
		description: 'Test description',
		content: '__Test content__\n- hello world\n- test123',
		date: 'June 12, 2021',
	},
];

const gradients = [['#9382ff', '#ba9cff']];

class Blog extends Component {
	constructor() {
		super();

		this.state = {
			showingPots: 2,
		};
	}

	render() {
		const blogMap = blogPosts
			.slice(0, this.state.showingPots)
			.map((post, index) => {
				return (
					<div className='blog-post' key={index}>
						<img
							src='https://regmedia.co.uk/2021/07/06/copilot.jpg'
							className='post-image'
						/>
						<div className='post-details'>
							<Gradient
								gradients={gradients}
								property='text'
								className='post-title'
								element='h1'
								angle='90deg'>
								{post.title}
							</Gradient>
							<span className='post-description'>
								{post.description}
							</span>
							<span className='post-date'>· {post.date}</span>
						</div>
					</div>
				);
			});

		return (
			<div className='blog'>
				<div className='blog-content'>
					<div className='blog-header'>
						{/* <Gradient
							gradients={gradients}
							property='text'
							className='header-title'
							element='h1'
							angle='90deg'>
							Personaized Blog
						</Gradient> */}
						<h1 className='header-title'>ThoughtLink Blog</h1>
						<span className='header-description'>
							Recent blog posts from ThoughtLink!
						</span>
					</div>
					<div className='blog-posts'>{blogMap}</div>
				</div>
				{this.state.showingPots < blogPosts.length && (
					<div>
						<Button
							transparent
							label='See More...'
							onClick={() => {
								this.setState({
									showingPots: this.state.showingPots + 2,
								});
							}}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default Blog;
