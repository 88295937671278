import { Component } from 'react';
import './AvatarEditor.scss';
import Label from 'Components/shared/Label/Label';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const avatars = {
	current:
		'https://hotpotmedia.s3.us-east-2.amazonaws.com/8-gZz63mf5PY3o0qD.png?nc=1',
	previous: [
		'https://hotpotmedia.s3.us-east-2.amazonaws.com/8-gZz63mf5PY3o0qD.png?nc=1',
		'https://hotpotmedia.s3.us-east-2.amazonaws.com/8-gZz63mf5PY3o0qD.png?nc=1',
		'https://hotpotmedia.s3.us-east-2.amazonaws.com/8-gZz63mf5PY3o0qD.png?nc=1',
		'https://hotpotmedia.s3.us-east-2.amazonaws.com/8-gZz63mf5PY3o0qD.png?nc=1',
		'https://hotpotmedia.s3.us-east-2.amazonaws.com/8-gZz63mf5PY3o0qD.png?nc=1',
	],
};

class AvatarEditor extends Component {
	constructor() {
		super();

		this.state = {};
	}

	render() {
		const previousAvatarsMap = avatars.previous.map((avatar, index) => {
			return (
				<div className='previous-avatar'>
					<img
						src={avatar}
						alt='avatar'
						className='previous-avatar-image'
					/>
				</div>
			);
		});

		return (
			<div className='avatar-editor'>
				<Label label='Edit your avatar' icon='fa-edit' />
				<div className='avatar'>
					<img
						src={avatars.current}
						alt='avatar'
						className='avatar-image'
					/>
				</div>
				<div className='previous-avatars'>{previousAvatarsMap}</div>
			</div>
		);
	}
}

export default AvatarEditor;
