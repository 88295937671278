import { Component } from 'react';
import './Toggle.scss';

class Toggle extends Component {
	constructor({ checked }) {
		super();

		this.state = {
			checked: checked ?? false,
		};
	}

	render() {
		const { label, onChange, ...rest } = this.props;
		const { checked } = this.state;

		return (
			<div className='toggle-container'>
				{label && (
					<span
						className={`toggle-label ${
							checked && 'toggle-label-checked'
						}`}>
						{label}
					</span>
				)}
				<label className='toggle'>
					<input
						type='checkbox'
						{...rest}
						onChange={() => {
							const checked = !this.state.checked;

							if (onChange) {
								onChange(checked);
							}

							this.setState({
								checked,
							});
						}}
					/>
					<span className='slider' />
				</label>
			</div>
		);
	}
}

export default Toggle;
