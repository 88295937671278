import {
	faCheckCircle,
	faCircleArrowDown,
	faCircleXmark,
	faRepeat,
	faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/shared/Button';
import { getCookie } from 'modules/cookies';
// import {
// 	requestChatGPTPaymentResponse,
// 	setUserUnlimitedChatGPTAccess,
// } from 'modules/web';
import { Component } from 'react';
import {
	ApplePay,
	CreditCard,
	PaymentForm,
	GooglePay,
} from 'react-square-web-payments-sdk';
import './Payment.scss';
import squarePay from 'modules/web/squarePay';
import { useParams } from 'react-router-dom';

class Payment extends Component {
	constructor() {
		super();

		this.state = {
			paymentSuccess: null,
		};
	}

	render() {
		const { paymentSuccess, payment } = this.state;
		const { paymentDetails } = this.props;

		return paymentSuccess ? (
			<div className='payment-status'>
				<FontAwesomeIcon
					className='success-status-icon'
					icon={faCheckCircle}
					size='2xl'
				/>
				<span>
					Your payment was <b>successful</b>!
				</span>
				<span>You may now continue to your upgraded acess!</span>
			</div>
		) : paymentSuccess === false ? (
			<div className='payment-status'>
				<FontAwesomeIcon
					className='fail-status-icon'
					icon={faCircleXmark}
					size='2xl'
				/>
				<span>
					Your payment <b>failed</b>!
				</span>
				<span>Please try again!</span>
				<Button
					icon={faRepeat}
					cta
					onClick={() => this.setState({ paymentSuccess: null })}
					label='Try Again'
				/>
			</div>
		) : (
			<div className='payment-request'>
				<div className='payment-container'>
					<span className='payment-total'>
						Monthly total: ${paymentDetails.price}.00
					</span>
					<PaymentForm
						applicationId='sq0idp-R2cfQJbZeZEWg5wwN0tufA'
						cardTokenizeResponseReceived={async (
							token,
							verifiedBuyer
						) => {
							const status = await squarePay.createPaymentRequest(
								{
									sourceId: token.token,
									email: getCookie('email'),
									password: getCookie('password'),
									plan: paymentDetails.plan,
								}
							);

							this.setState({
								paymentSuccess: status === 'Success',
								payment,
							});
						}}
						locationId='LEVV15Q3AGXXR'>
						<CreditCard
							style={{
								'.message-text': {
									color: '#a0a0a0',
								},
								'.message-icon': {
									color: '#a0a0a0',
								},
							}}
						/>
					</PaymentForm>
				</div>
			</div>
		);
	}
}

function PaymentWrapper() {
	const { tier } = useParams();

	if (tier !== 'Pro' && tier !== 'Ultimate') {
		window.location = '/';

		return;
	}

	const paymentDetails = {
		plan: tier,
		price: tier === 'Pro' ? 10 : tier === 'Ultimate' && 20,
	};

	return (
		<div className='payment-wrapper'>
			<Payment paymentDetails={paymentDetails} />
		</div>
	);
}

export default PaymentWrapper;
