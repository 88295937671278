import axios from 'axios';

const baseUrl = window.location.href.includes('localhost')
	? 'http://localhost:5000/thoughtlink-app/us-central1/app'
	: 'https://us-central1-thoughtlink-app.cloudfunctions.net/app';

async function createUser({ firstName, lastName, email, password }) {
	try {
		const response = await axios.post(`${baseUrl}/sign-up`, {
			firstName,
			lastName,
			email,
			password,
		});

		return response.data;
	} catch (err) {
		return;
	}
}

async function loginUser({ email, password }) {
	try {
		const response = await axios.post(`${baseUrl}/login`, {
			email,
			password,
		});

		return response.data;
	} catch (err) {
		return;
	}
}

async function addUserConversation({ email, password }) {
	try {
		const response = await axios.post(`${baseUrl}/add-user-conversation`, {
			email,
			password,
		});

		return response.data;
	} catch (err) {
		return;
	}
}

async function updateConversationName({
	email,
	password,
	conversationId,
	conversationName,
}) {
	try {
		const response = await axios.post(
			`${baseUrl}/update-conversation-name`,
			{
				email,
				password,
				conversationId,
				conversationName,
			}
		);

		return response.data;
	} catch (err) {
		return;
	}
}

async function updateConversationVisibility({
	email,
	password,
	conversationId,
	visibility,
}) {
	try {
		const response = await axios.post(
			`${baseUrl}/update-conversation-visibility`,
			{
				email,
				password,
				conversationId,
				visibility,
			}
		);

		return response.data;
	} catch (err) {
		return;
	}
}

async function updateConversationMessages({
	email,
	password,
	conversationId,
	messages,
}) {
	try {
		const response = await axios.post(
			`${baseUrl}/update-conversation-messages`,
			{
				email,
				password,
				conversationId,
				messages,
			}
		);

		return response.data;
	} catch (err) {
		return;
	}
}

async function updateConversationPinned({
	email,
	password,
	conversationId,
	pinned,
}) {
	try {
		const response = await axios.post(
			`${baseUrl}/update-conversation-pinned`,
			{
				email,
				password,
				conversationId,
				pinned,
			}
		);

		return response.data;
	} catch (err) {
		return;
	}
}

async function getUserConversations({ email, password }) {
	try {
		const response = await axios.post(`${baseUrl}/get-user-conversations`, {
			email,
			password,
		});

		return response.data;
	} catch (err) {
		return;
	}
}

async function deleteUserConversation({ email, password, conversationId }) {
	try {
		const response = await axios.post(
			`${baseUrl}/delete-user-conversation`,
			{
				email,
				password,
				conversationId,
			}
		);

		return response.data;
	} catch (err) {
		return;
	}
}

async function setUserStreamResponses({ email, password, enabled }) {
	try {
		const response = await axios.post(
			`${baseUrl}/set-user-stream-responses`,
			{
				email,
				password,
				enabled,
			}
		);

		return response.data;
	} catch (err) {
		return;
	}
}

async function setUserPersonalityDetails({ email, password, personality }) {
	try {
		const response = await axios.post(
			`${baseUrl}/set-user-personality-details`,
			{
				email,
				password,
				personality,
			}
		);

		return response.data;
	} catch (err) {
		return;
	}
}

export default {
	createUser,
	loginUser,
	getUserConversations,
	addUserConversation,
	setUserStreamResponses,
	setUserPersonalityDetails,
	updateConversationName,
	updateConversationVisibility,
	updateConversationPinned,
	updateConversationMessages,
	deleteUserConversation,
};
