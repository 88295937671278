import { Gradient } from 'react-gradient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Label.scss';

const gradients = [['#9382ff', '#ba9cff']];

function Label({ className, icon, label, ...rest }) {
	const hasIcon = icon;

	return (
		<div
			className={`label ${hasIcon && 'label-icon'} ${className}`}
			{...rest}>
			{icon && (
				<Gradient
					gradients={gradients}
					property='text'
					element='i'
					className={`fa-solid ${icon}`}
					angle='90deg'
				/>
			)}
			<Gradient
				gradients={gradients}
				property='text'
				element='span'
				angle='90deg'>
				{label}
			</Gradient>
		</div>
	);
}

export default Label;
