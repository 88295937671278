import { Component, createRef } from 'react';
import './Profile.scss';
import Label from 'Components/shared/Label/Label';
import {
	faEdit,
	faFireFlameCurved,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from 'assets/images/Logo3.png';
import { Link } from 'react-router-dom';

const user = {
	avatarURL:
		'https://hotpotmedia.s3.us-east-2.amazonaws.com/8-gZz63mf5PY3o0qD.png?nc=1',
	fullName: 'Caden Marinozzi',
	birthdate: '06/12/2007',
	bio: `A hard working software engineer and student who is passionate about learning new things and helping others.`,
	personality: [
		{
			trait: 'Kind',
			icon: 'fa-child-reaching',
		},
		{
			trait: 'Hard Working',
			icon: 'fa-briefcase',
		},
		{
			trait: 'Intelligent',
			icon: 'fa-brain',
		},
		{
			trait: 'Creative',
			icon: 'fa-palette',
		},
		{
			trait: 'Funny',
			icon: 'fa-laugh',
		},
		{
			trait: 'Loyal',
			icon: 'fa-handshake',
		},
		{
			trait: 'Honest',
			icon: 'fa-user-check',
		},
		{
			trait: 'Self Motivated',
			icon: 'fa-rocket',
		},
		{
			trait: 'Passionate',
			icon: 'fa-heart',
		},
	],
	websites: [
		{
			name: 'LinkedIn',
			icon: 'fa-linkedin',
		},
		{
			name: 'GitHub',
			icon: 'fa-github',
		},
		{
			name: 'Twitter',
			icon: 'fa-twitter',
		},
		{
			name: 'Facebook',
			icon: 'fa-facebook',
		},
		{
			name: 'Instagram',
			icon: 'fa-instagram',
		},
	],
	conversations: [
		{
			name: 'Welcome',
			date: new Date().toLocaleDateString(),
			messages: [
				{
					role: 'assistant',
					content: 'Hello, welcome to the chat!',
					date: new Date().toLocaleDateString(),
				},
				{
					role: 'user',
					content: 'Hello, welcome to the chat!',
					date: new Date().toLocaleDateString(),
				},
			],
		},
		{
			name: 'Welcome 2',
			date: new Date().toLocaleDateString(),
			messages: [
				{
					role: 'assistant',
					content: 'Hello, welcome to the chat!',
					date: new Date().toLocaleDateString(),
				},
				{
					role: 'user',
					content: 'Hello, welcome to the chat!',
					date: new Date().toLocaleDateString(),
				},
			],
		},
	],
};

class Profile extends Component {
	constructor() {
		super();

		this.state = {};

		this.bioRef = createRef();
	}

	render() {
		const { editingBio } = this.state;

		const age = Math.floor(
			(new Date() - new Date(user.birthdate).getTime()) / 3.15576e10
		);

		const personalityMap = user.personality.slice(0, 5).map((trait) => {
			return (
				<div className='personality-trait'>
					<i className={`fas ${trait.icon} icon`}></i>
					<span className='trait'>{trait.trait}</span>
				</div>
			);
		});

		const websitesMap = user.websites.slice(0, 3).map((website) => {
			return (
				<div className='website'>
					<i className={`fab ${website.icon} icon`}></i>
					<span className='name'>{website.name}</span>
				</div>
			);
		});

		const conversationsMap = user.conversations
			.slice(0, 5)
			.map((conversation) => {
				return (
					<div className='conversation'>
						<div className='conversation-header'>
							<span className='name'>{conversation.name}</span>
						</div>
						<div className='messages'>
							{conversation.messages.map((message) => {
								return (
									<div className='message'>
										<div className={`message-avatar`}>
											{message.role === 'user' ? (
												<img
													src={user.avatarURL}
													width={25}
													height={25}
													alt='Avatar'
													className='message-user-avatar'
												/>
											) : (
												<img
													src={Logo}
													width={25}
													height={25}
													alt='Avatar'
												/>
											)}
										</div>
										<span className='message-content'>
											{message.content}
										</span>
									</div>
								);
							})}
						</div>
					</div>
				);
			});

		return (
			<div className='profile'>
				<Label label='Your unique profile' icon='fa-user' />
				<div className='profile-content'>
					{' '}
					<Link to='/avatar-editor'>
						<div className='avatar'>
							<div className='user-tier pro-tier'>Pro</div>
							<div className='user-tier unlimited-tier'>
								<FontAwesomeIcon icon={faFireFlameCurved} />{' '}
								Unlimited
							</div>
							<img
								width={300}
								height={300}
								className='avatar-image'
								src={user.avatarURL}
								alt='Avatar'
							/>
							<div className='edit-overlay'>
								<FontAwesomeIcon icon={faEdit} /> Edit Avatar
							</div>
						</div>
					</Link>
					<div className='profile-details'>
						<span className='name'>{user.fullName}</span>
						<span className='age'>{age} years old</span>
					</div>
					<div className='profile-personality'>
						{personalityMap}{' '}
						<span className='more-label'>See all...</span>
					</div>
					<div className='profile-websites'>
						{websitesMap}
						<span className='more-label'>More...</span>
					</div>
					<div className='profile-bio'>
						<span
							className='bio'
							ref={this.bioRef}
							contentEditable={editingBio}
							onKeyDown={(event) => {
								if (event.key === 'Enter') {
									this.setState({
										editingBio: false,
									});
								}
							}}
							onBlur={() => {
								this.setState({
									editingBio: false,
								});
							}}>
							{user.bio}
							<FontAwesomeIcon
								icon={faEdit}
								onClick={() => {
									this.setState(
										{
											editingBio: !editingBio,
										},
										() => {
											this.bioRef.current.focus();
										}
									);
								}}
								className='edit-icon'
							/>
						</span>
					</div>
					<div className='profile-conversations'>
						{conversationsMap}
					</div>
				</div>
			</div>
		);
	}
}

export default Profile;
