import { Component } from 'react';
import './Login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faLock,
	faUser,
	faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Button from 'Components/shared/Button/Button';
import Input from 'Components/shared/Input/Input';
import Form from 'Components/shared/Form/Form';
import Label from 'Components/shared/Label/Label';
import firebase from 'modules/web/firebase';
import { setCookie } from 'modules/cookies';

class Login extends Component {
	constructor() {
		super();

		this.state = {};
	}

	async submit() {
		const { email, password } = this.state;

		if (!email || !password) {
			this.setState({
				submitted: true,
				error: 'Missing fields',
			});

			return;
		}

		this.setState({
			loading: true,
		});

		const user = await firebase.loginUser({
			email,
			password,
		});

		if (!user) {
			this.setState({
				loading: false,
				error: 'An error occurred',
			});

			return;
		}

		setCookie('email', email);
		setCookie('password', password);

		this.setState(
			{
				loading: false,
			},
			() => {
				window.location = '/';
			}
		);
	}

	render() {
		const { submitted, error, loading } = this.state;

		return (
			<Form>
				<div className='form'>
					<div className='header'>
						<Label
							icon='fa-user'
							label='Already have an account?'
						/>
						<span className='subtitle'>
							Let's get you logged in
						</span>
					</div>
					<div className='inputs'>
						<Input
							label='Email'
							placeholder='Enter your email'
							onChange={(email) => {
								this.setState({
									email,
								});
							}}
							submitted={submitted}
							required
						/>
						<Input
							label='Password'
							placeholder='Enter a password'
							type='password'
							submitted={submitted}
							required
							onChange={(password) => {
								this.setState({
									password,
								});
							}}
						/>
						{error && <span className='error'>{error}</span>}
					</div>
					<div className='controls'>
						{loading && (
							<i className='fa fa-spinner-third fa-spin' />
						)}
						<div className='buttons'>
							<Button
								label='Submit'
								onClick={this.submit.bind(this)}
								cta
							/>
							<Link to='/sign-up'>
								<Button label='Sign Up' transparent />
							</Link>
						</div>
					</div>
				</div>
			</Form>
		);
	}
}

export default Login;
