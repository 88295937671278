import { Component } from 'react';
import './Settings.scss';
import Button from 'Components/shared/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMultiply } from '@fortawesome/free-solid-svg-icons';
import { setCookie } from 'modules/cookies';
import Toggle from 'Components/shared/Toggle/Toggle';

class Settings extends Component {
	constructor() {
		super();

		this.state = {};
	}

	logout() {
		setCookie('email', '');
		setCookie('password', '');

		window.location = '/';
	}

	cancelSubscription() {
		
	}

	render() {
		const { user, closeSettings, theme, setTheme } = this.props;

		return (
			<div className='settings-container'>
				<div className='settings'>
					<div className='header'>
						<h1>Settings</h1>
						<FontAwesomeIcon
							className='close-icon'
							icon={faMultiply}
							onClick={closeSettings}
						/>
					</div>
					<Toggle
						checked={theme === 'light'}
						label={'Light Mode'}
						onChange={(theme) => {
							setTheme(theme ? 'light' : 'dark');
						}}
					/>
					<div className='section'>
						<h1>Subscription</h1>
						<span>
							You are currently on the <b>{user.tier}</b>{' '}
							subscription.
						</span>
						<div>
							<Button label='Cancel Subscription' danger onClick={this.cancelSubscription.bind(this)} />
						</div>
					</div>
					<div className='section'>
						<h1>User</h1>
						<span>Logged in as: {user.email}</span>
						<div>
							<Button
								label='Logout'
								onClick={this.logout.bind(this)}
								danger
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Settings;
