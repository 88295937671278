import { Component } from 'react';
import './Navbar.scss';
import Button from 'Components/shared/Button/Button';
import { Link, NavLink } from 'react-router-dom';
import { setCookie } from 'modules/cookies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faMoon, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import Logo from 'assets/images/Logo3.png';

class Navbar extends Component {
	constructor() {
		super();

		this.state = {};
	}

	render() {
		const { loggedIn, completedSetup } = this.props;

		return (
			<div className='navbar-container'>
				<div className='navbar'>
					<Link to='/'>
						<div className='section'>
							<div className='logo'>
								<img src={Logo} className='logo-img' />
								<span>ThoughtLink</span>
							</div>
						</div>
					</Link>
					{!loggedIn ? (
						<>
							<div className='section'>
								<div className='navigation'>
									<NavLink
										exact
										to='/'
										className={({ isActive, isPending }) =>
											isPending
												? 'link'
												: isActive
												? 'link-active'
												: 'link'
										}>
										Home
									</NavLink>
									<NavLink
										exact
										to='/about'
										className={({ isActive, isPending }) =>
											isPending
												? 'link'
												: isActive
												? 'link-active'
												: 'link'
										}>
										About
									</NavLink>
									<NavLink
										exact
										to='/blog'
										className={({ isActive, isPending }) =>
											isPending
												? 'link'
												: isActive
												? 'link-active'
												: 'link'
										}>
										Blog
									</NavLink>
									<NavLink
										to='/pricing'
										className={({ isActive, isPending }) =>
											isPending
												? 'link'
												: isActive
												? 'link-active'
												: 'link'
										}>
										Price
									</NavLink>
								</div>
							</div>
							<div className='section'>
								<div className='buttons'>
									<NavLink to='/login'>
										<Button label='Login' transparent />
									</NavLink>
									<NavLink to='/sign-up'>
										<Button label='Sign Up' />
									</NavLink>
								</div>
							</div>
						</>
					) : completedSetup ? (
						<>
							<div className='section'>
								<div className='navigation'>
									<NavLink
										to='/chat'
										className={({ isActive, isPending }) =>
											isPending
												? 'link'
												: isActive ||
												  window.location.pathname ===
														'/'
												? 'link-active'
												: 'link'
										}>
										Chat
									</NavLink>
									<NavLink
										exact
										to='/blog'
										className={({ isActive, isPending }) =>
											isPending
												? 'link'
												: isActive
												? 'link-active'
												: 'link'
										}>
										Blog
									</NavLink>
									<NavLink
										exact
										to='/upgrade'
										className={({ isActive, isPending }) =>
											isPending
												? 'link'
												: isActive
												? 'link-active'
												: 'link'
										}>
										Upgrade
									</NavLink>
								</div>
							</div>
							<div className='section'>
								<div className='buttons'>
									<FontAwesomeIcon
										icon={faCog}
										onClick={this.props.toggleSettings}
										className='theme-icon'
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<div className='section'>
								<div className='navigation'>
									<NavLink
										exact
										to='/'
										className={({ isActive, isPending }) =>
											isPending
												? 'link'
												: isActive
												? 'link-active'
												: 'link'
										}>
										Survey
									</NavLink>
								</div>
							</div>
							<div className='section'>
								<div className='buttons'>
									<FontAwesomeIcon
										icon={faCog}
										onClick={this.props.toggleSettings}
										className='theme-icon'
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default Navbar;
