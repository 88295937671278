import { Component } from 'react';
import './HorizontalSelector.scss';

class HorizontalSelector extends Component {
	constructor({ onChange }) {
		super();

		this.state = {
			selectedItem: 0,
		};

		if (onChange) {
			onChange(0);
		}
	}

	handleSelectItem(selectedItem) {
		const { onChange } = this.props;

		this.setState({
			selectedItem,
		});

		if (onChange) {
			onChange(selectedItem);
		}
	}

	render() {
		const { selectedItem } = this.state;
		const { items } = this.props;

		const itemsMap = items.map((item, index) => {
			const selected = selectedItem === index;

			return (
				<div
					className={`item ${selected && 'item-selected'}`}
					onClick={this.handleSelectItem.bind(this, index)}
					key={index}>
					<span className='item-text'>{item}</span>
				</div>
			);
		});

		return <div className='horizontal-selector'>{itemsMap}</div>;
	}
}

export default HorizontalSelector;
