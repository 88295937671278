import { City, State, Country } from 'country-state-city';
import { Component } from 'react';

import './Survey.scss';
import Button from 'Components/shared/Button/Button';
import HorizontalSelector from 'Components/shared/HorizontalSelector/HorizontalSelector';
import firebase from 'modules/web/firebase';
import { getCookie } from 'modules/cookies';
import Label from 'Components/shared/Label/Label';

const countryCode = 'US';
const country = Country.getCountryByCode(countryCode);
const states = State.getStatesOfCountry(country.isoCode);

const statesList = [];
const citiesList = {};

states.forEach((state) => {
	statesList.push(state.name);
	const cities = City.getCitiesOfState(countryCode, state.isoCode);

	citiesList[state.name] = [];

	cities.forEach((city) => {
		citiesList[state.name].push(city.name);
	});
});

class Survey extends Component {
	constructor() {
		super();

		this.state = {
			currentCitiesList: [],
		};
	}

	updateSurvey() {
		this.setState({
			survey: [
				{
					title: 'Details',
					questions: [
						{
							question: 'How old are you?',
							type: 'number',
						},
						{
							question: 'What state do you live in?',
							type: 'text-list',
							list: statesList,
							onChange: (e) => {
								this.setState(
									{
										currentCitiesList:
											citiesList[e.target.value] || [],
									},
									this.updateSurvey.bind(this)
								);
							},
						},
						{
							question: 'What city do you live in?',
							type: 'text-list',
							list: this.state.currentCitiesList,
						},
					],
				},
				{
					title: 'Personality',
					questions: [
						{
							question:
								'Do you usually enjoy being the center of attention?',
							type: 'single-choice',
							answers: ['Yes', 'No'],
						},
						{
							question:
								'Do you usually get your tasks done as soon as possible?',
							type: 'single-choice',
							answers: ['Yes', 'No'],
						},
						{
							question:
								'Do you usually react well to unexpected changes?',
							type: 'single-choice',
							answers: ['Yes', 'No'],
						},
						{
							question:
								'Do you usually enjoy the process of solving a problem?',
							type: 'single-choice',
							answers: ['Yes', 'No'],
						},
						{
							question:
								'Choose the words that best describe you.',
							type: 'multiple-choice-buttons',
							answers: [
								{
									answer: 'Artistic',
								},
								{
									answer: 'Balanced',
								},
								{
									answer: 'Chill',
								},
								{
									answer: 'Direct',
								},
								{
									answer: 'Hard Working',
								},
								{
									answer: 'Intelligent',
								},
								{
									answer: 'Energetic',
								},
								{
									answer: 'Funny',
								},
								{
									answer: 'Passionate',
								},
								{
									answer: 'Patient',
								},
								{
									answer: 'Reliable',
								},
								{
									answer: 'Kind',
								},
								{
									answer: 'Creative',
								},
								{
									answer: 'Leader',
								},
								{
									answer: 'Spontaneous',
								},
								{
									answer: 'Organized',
								},
								{
									answer: 'Adventurous',
								},
								{
									answer: 'Ambitious',
								},
							],
						},
					],
				},
				{
					title: 'Description',
					questions: [
						{
							question: 'Write a short description of yourself.',
							type: 'textbox',
						},
					],
				},
			],
		});
	}

	async submit() {
		await firebase.setUserPersonalityDetails({
			email: getCookie('email'),
			password: getCookie('password'),
			personality: { ...this.state.results },
		});

		window.location = '/upgrade';
	}

	componentDidMount() {
		this.updateSurvey();
	}

	componentDidUpdate() {}

	render() {
		if (!this.state.survey) return;

		const surveyMap = this.state.survey.map((section, index) => {
			return (
				<div className='section'>
					<h3 className='section-title'>{section.title}</h3>
					<ol className='questions'>
						{section.questions.map((question, index) => {
							return (
								<li className='question'>
									<div className='question-content'>
										<div className='header'>
											<span className='question-text'>
												{question.question}
											</span>
										</div>
										<div className='answers'>
											{question.type === 'number' ? (
												<input
													type='number'
													onChange={(event) => {
														if (question.onChange)
															question.onChange(
																event
															);

														this.setState({
															results: {
																...this.state
																	.results,
																[question.question]:
																	event.target
																		.value,
															},
														});
													}}
												/>
											) : question.type ===
											  'single-choice' ? (
												<HorizontalSelector
													items={question.answers}
													onChange={(value) => {
														value = value === 0;

														if (question.onChange)
															question.onChange(
																value
															);

														this.setState({
															results: {
																...this.state
																	.results,
																[question.question]:
																	value,
															},
														});
													}}
												/>
											) : question.type ===
											  'text-list' ? (
												<select
													onChange={(event) => {
														if (question.onChange)
															question.onChange(
																event
															);

														this.setState({
															results: {
																...this.state
																	.results,
																[question.question]:
																	event.target
																		.value,
															},
														});
													}}>
													{question.list.map(
														(item, index) => {
															return (
																<option
																	value={
																		item
																	}>
																	{item}
																</option>
															);
														}
													)}
												</select>
											) : question.type ===
											  'multiple-choice-buttons' ? (
												<div className='multiple-choice'>
													{question.answers.map(
														(answer, index) => {
															const selected =
																this.state
																	.results?.[
																	question
																		.question
																]?.[
																	answer
																		.answer
																];

															if (
																!this.state
																	.results?.[
																	question
																		.question
																]
															) {
																this.setState({
																	results: {
																		...this
																			.state
																			.results,
																		[question.question]:
																			{},
																	},
																});
															}

															// return (
															// 	<label className='answer-label'>
															// 		<input
															// 			type='checkbox'
															// 			name='checkbox'
															// 			onChange={(
															// 				event
															// 			) => {
															// 				if (
															// 					question.onChange
															// 				)
															// 					question.onChange(
															// 						event
															// 					);

															// 				this.setState(
															// 					{
															// 						results:
															// 							{
															// 								...this
															// 									.state
															// 									.results,
															// 								[question.question]:
															// 									answer.answer,
															// 							},
															// 					}
															// 				);
															// 			}}
															// 		/>
															// 		{
															// 			answer.answer
															// 		}
															// 	</label>
															// );
															return (
																<div
																	className={`personality-trait ${
																		selected &&
																		'personality-trait-selected'
																	}`}
																	onClick={(
																		event
																	) => {
																		if (
																			question.onChange
																		)
																			question.onChange(
																				event
																			);

																		this.setState(
																			{
																				results:
																					{
																						...this
																							.state
																							.results,
																						[question.question]:
																							{
																								...this
																									.state
																									.results[
																									question
																										.question
																								],
																								[answer.answer]:
																									!selected,
																							},
																					},
																			}
																		);
																	}}>
																	<span className='trait'>
																		{
																			answer.answer
																		}
																	</span>
																</div>
															);
														}
													)}
												</div>
											) : (
												question.type === 'textbox' && (
													<textarea
														onChange={(e) => {
															if (
																question.onChange
															)
																question.onChange(
																	e.target
																		.value
																);

															this.setState({
																results: {
																	...this
																		.state
																		.results,
																	[question.question]:
																		e.target
																			.value,
																},
															});
														}}
														className='textbox'
														placeholder='Begin typing here...'
													/>
												)
											)}
										</div>
									</div>
								</li>
							);
						})}
					</ol>
				</div>
			);
		});

		return (
			<div className='survey'>
				<Label label='Survey' />
				<span className='description'>
					Before we get started, take this short survey to show the AI
					your personality! Be as honest with our answers as possible.
					All answers are private.
				</span>
				<div className='sections'>{surveyMap}</div>
				<div className='submit'>
					<Button
						label='Submit'
						cta
						onClick={this.submit.bind(this)}
					/>
				</div>
			</div>
		);
	}
}

export default Survey;
