import { Component, createRef } from 'react';
import './Chat.scss';
import Input from 'Components/shared/Input/Input';
import Button from 'Components/shared/Button/Button';
import {
	faArrowRight,
	faClone,
	faComment,
	faCopy,
	faEdit,
	faEye,
	faEyeSlash,
	faInfoCircle,
	faPaperPlane,
	faPenNib,
	faRotateRight,
	faSpinner,
	faSquare,
	faThumbtack,
	faLock,
	faLockOpen,
	faTrashCan,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faSquare as faSquareRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'Components/shared/Tooltip/Tooltip';
import { getCookie } from 'modules/cookies';
import chatGPT from 'modules/web/chatGPT';
import { Gradient } from 'react-gradient';
import firebase from 'modules/web/firebase';
import utils from 'modules/utils';
import { rewriteText } from 'modules/web/rewriter';
import Toggle from 'Components/shared/Toggle/Toggle';
import MarkdownIt from 'markdown-it';
import mdKatex from 'markdown-it-katex';
import mdHighlight from 'markdown-it-highlightjs';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import Logo from 'assets/images/Logo3.png';

const user = {
	avatarURL:
		'https://hotpotmedia.s3.us-east-2.amazonaws.com/8-gZz63mf5PY3o0qD.png?nc=1',
};

const gradients = [['#9382ff', '#ba9cff']];

class Chat extends Component {
	constructor({ user }) {
		super();

		this.state = {
			selectedConversation: 0,
			selectedConversations: [],
			streamResponses: user.streamResponses,
		};
		this.messagesRef = createRef();
		this.selectedNameRef = createRef();
	}

	async getConversations() {
		let conversations =
			(await firebase.getUserConversations({
				email: getCookie('email'),
				password: getCookie('password'),
			})) || [];

		conversations = [
			{
				name: 'Welcome to ThoughtLink!',
				messages: [
					{
						content: `Welcome to ThoughtLink! Here\'s how it works:\n\n## Conversations\n- Create a conversation by clicking the "New Conversation" button.\n- You can rename a conversation by clicking the edit icon next to the conversation name and typing in the new name.\n- You can select conversations by clicking the selection box next to the names of the conversations you want to select.\n- You can select many conversations by clicking on the starting conversation, holding shift, and clicking on the ending conversation.\n- With a conversation selected you can pin the conversation, delete the conversation, or change the conversation's visibility by clicking the icons at the bottom of the conversations.\n- Pinning a conversation will put it at the top of your conversations list.\n\n## Messages\n- Type your message in the input box and press Send to send the message!\n- Hovering over a message sent by the assistant will show you the options to regenerate the response, copy the message, and with the Pro tier or Ultimate tier, rewrite the message.\n- Toggling "Stream Responses" will gradually send the responses generated by the assistant as the response is generated, instead of all at once.`,
						role: 'assistant',
					},
				],
				welcomeMessage: true,
			},
		].concat(conversations);

		this.setState({
			conversations,
			loaded: true,
		});
	}

	handleKeyDown(event) {
		if (event.key === 'Shift') {
			this.setState({
				shiftDown: true,
			});
		}
	}

	handleKeyUp(event) {
		if (event.key === 'Shift') {
			this.setState({
				shiftDown: false,
			});
		}
	}

	async componentDidMount() {
		this.getConversations();
		this.updateHighlightJS();

		document.addEventListener('keydown', this.handleKeyDown.bind(this));
		document.addEventListener('keyup', this.handleKeyUp.bind(this));
	}

	async deleteConversation(index) {
		await firebase.deleteUserConversation({
			email: getCookie('email'),
			password: getCookie('password'),
			conversationId: this.state.conversations[index].id,
		});

		this.getConversations();
	}

	regenerateResponse() {
		const selectedConversation =
			this.state.conversations[this.state.selectedConversation];

		selectedConversation.messages.pop();
		const message =
			selectedConversation.messages[
				selectedConversation.messages.length - 1
			];

		selectedConversation.messages.pop();

		this.setState(
			{
				message: message.content,
			},
			this.sendMessage.bind(this)
		);
	}

	async addConversation() {
		if (
			this.props.user.tier === 'Free' &&
			this.state.conversations.length >= 4
		) {
			this.setState({
				conversationsError: 'Upgrade to add more conversations!',
			});

			return;
		}

		await firebase.addUserConversation({
			email: getCookie('email'),
			password: getCookie('password'),
		});

		this.getConversations();
	}

	activateConversation(index) {
		this.setState({
			selectedConversation: index,
		});
	}

	updateHighlightJS() {
		hljs.configure({ ignoreUnescapedHTML: true });
		hljs.highlightAll();
	}

	componentDidUpdate() {
		this.updateHighlightJS();
	}

	async streamPromptChatGPT() {
		const { message } = this.state;

		if (!message) {
			return;
		}

		let conversations = [...this.state.conversations];

		if (!conversations[this.state.selectedConversation].messages) {
			conversations[this.state.selectedConversation].messages = [];
		}

		let selectedConversation =
			conversations[this.state.selectedConversation];

		conversations[this.state.selectedConversation].messages.push({
			role: 'user',
			content: message,
			date: new Date().toLocaleDateString(),
		});

		this.setState({
			conversations,
			message: '',
		});

		const messages = selectedConversation.messages.map((message) => {
			return {
				role: message.role,
				content: message.content,
			};
		});

		const email = getCookie('email');
		const password = getCookie('password');

		let response = '';
		let first = true;

		await chatGPT.streamChatGPT({
			messages,
			email,
			password,
			onChunk: (chunk) => {
				response += chunk;

				if (!first) {
					selectedConversation.messages[
						selectedConversation.messages.length - 1
					] = {
						role: 'assistant',
						content: response,
						date: selectedConversation.messages[
							selectedConversation.messages.length - 1
						].date,
					};

					this.setState({
						conversations,
					});
				}

				if (first) {
					first = false;

					conversations[
						this.state.selectedConversation
					].messages.push({
						role: 'assistant',
						content: response,
						date: new Date().toLocaleDateString(),
					});
				}
			},
		});

		await firebase.updateConversationMessages({
			email,
			password,
			conversationId: conversations[this.state.selectedConversation].id,
			messages: conversations[this.state.selectedConversation].messages,
		});
	}

	async promptChatGPT() {
		const { message } = this.state;

		if (!message) {
			return;
		}

		const conversations = [...this.state.conversations];

		if (!conversations[this.state.selectedConversation].messages) {
			conversations[this.state.selectedConversation].messages = [];
		}

		conversations[this.state.selectedConversation].messages.push({
			role: 'user',
			content: message,
			date: new Date().toLocaleDateString(),
		});

		this.setState({
			conversations,
			message: '',
		});

		const messages = conversations[
			this.state.selectedConversation
		].messages.map((message) => {
			return {
				role: message.role,
				content: message.content,
			};
		});

		const email = getCookie('email');
		const password = getCookie('password');

		this.setState({
			loadingResponse: true,
		});

		const response = await chatGPT.promptChatGPT({
			messages,
			email,
			password,
		});

		conversations[this.state.selectedConversation].messages.push({
			role: 'assistant',
			embedding: response.embedding,
			content: response.text,
			date: new Date().toLocaleDateString(),
		});

		await firebase.updateConversationMessages({
			email,
			password,
			conversationId: conversations[this.state.selectedConversation].id,
			messages: conversations[this.state.selectedConversation].messages,
		});

		this.setState({
			loadingResponse: false,
			conversations,
		});
	}

	async sendMessage() {
		if (this.state.loadingResponse) {
			return;
		}

		if (this.state.streamResponses) {
			await this.streamPromptChatGPT();

			return;
		}

		await this.promptChatGPT();

		this.scrollToBottom();
	}

	selectMultipleConversations(index) {
		// select all of the conversations between the active conversation and the clicked conversation

		const indexes = [];

		if (index > this.state.selectedConversation) {
			for (let i = this.state.selectedConversation; i <= index; i++) {
				indexes.push(i);
			}
		}

		if (index < this.state.selectedConversation) {
			for (let i = index; i <= this.state.selectedConversation; i++) {
				indexes.push(i);
			}
		}

		this.setState({
			selectedConversations: indexes,
		});
	}

	selectConversation(index) {
		if (this.state.shiftDown) {
			this.selectMultipleConversations(index);

			return;
		}

		const selectedConversations = [...this.state.selectedConversations];

		if (selectedConversations.includes(index)) {
			selectedConversations.splice(
				selectedConversations.indexOf(index),
				1
			);
		} else {
			selectedConversations.push(index);
		}

		this.setState({
			selectedConversations,
		});
	}

	async togglePrivacy() {
		for (let index of this.state.selectedConversations) {
			await this.toggleSinglePrivacy(index);
		}
	}

	async togglePin() {
		for (let index of this.state.selectedConversations) {
			await this.toggleSinglePin(index);
		}
	}

	async toggleSinglePin(index) {
		await firebase.updateConversationPinned({
			email: getCookie('email'),
			password: getCookie('password'),
			pinned: !this.state.conversations[index].pinned,
			conversationId: this.state.conversations[index].id,
		});

		this.getConversations();
	}

	async toggleSinglePrivacy(index) {
		await firebase.updateConversationVisibility({
			email: getCookie('email'),
			password: getCookie('password'),
			visibility: !this.state.conversations[index].public,
			conversationId: this.state.conversations[index].id,
		});

		this.getConversations();
	}

	async deleteConversations() {
		// delete the selected conversations

		for (let index of this.state.selectedConversations) {
			await this.deleteConversation(index);
		}

		this.setState({
			selectedConversations: [],
		});
	}

	async rewriteResponse(index) {
		const email = getCookie('email');
		const password = getCookie('password');

		const conversations = [...this.state.conversations];
		const selectedConversation =
			conversations[this.state.selectedConversation];

		const message = selectedConversation.messages[index];

		selectedConversation.messages[index].content = await rewriteText({
			text: message.content,
		});

		await firebase.updateConversationMessages({
			email,
			password,
			conversationId: conversations[this.state.selectedConversation].id,
			messages: conversations[this.state.selectedConversation].messages,
		});

		this.setState({
			loadingResponse: false,
			conversations,
		});
	}

	async getSimilarAnswers() {
		const { showSimilarIndex } = this.state;

		const message =
			this.state.conversations?.[this.state.selectedConversation]
				?.messages?.[showSimilarIndex].content;

		const similarAnswers = await firebase.getSimilarAnswers({
			email: getCookie('email'),
			password: getCookie('password'),
			message,
		});

		this.setState({
			similarAnswers,
		});
	}

	scrollToBottom() {
		const messages = this.messagesRef.current;

		messages.scrollTop = messages.scrollHeight;
	}

	async toggleStreamResponses(enabled) {
		this.setState({
			streamResponses: enabled,
		});
		await firebase.setUserStreamResponses({
			email: getCookie('email'),
			password: getCookie('password'),
			enabled,
		});
	}

	async updateConversationName(name) {
		const conversationId =
			this.state.conversations[this.state.selectedConversation].id;

		await firebase.updateConversationName({
			email: getCookie('email'),
			password: getCookie('password'),
			conversationId,
			conversationName: name,
		});
	}

	render() {
		if (!this.state.loaded || !this.state.conversations) {
			return;
		}

		const conversationsMap = this.state.conversations
			.map((conversation, index) => {
				conversation._index = index;

				return conversation;
			})
			.sort((a, b) => {
				if (a._index === 0 || b._index === 0) {
					return true;
				}

				return a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1;
			})
			.map((conversation, index) => {
				const active = this.state.selectedConversation === index;
				const selected =
					this.state.selectedConversations.includes(index);

				return (
					<div
						key={index}
						className={`conversation ${
							active && 'conversation-selected'
						}`}
						onClick={this.activateConversation.bind(this, index)}>
						<FontAwesomeIcon
							icon={selected ? faSquare : faSquareRegular}
							onClick={this.selectConversation.bind(this, index)}
							className={`icon select-icon ${
								selected && 'select-icon-selected'
							} ${
								!conversation.welcomeMessage && 'icon-visible'
							}`}
						/>
						<div className='details'>
							<span
								ref={active && this.selectedNameRef}
								className={`name ${
									this.state.conversationEditIndex ===
										index && 'name-edit'
								}`}
								onBlur={() => {
									this.setState({
										conversationEditIndex: null,
									});
								}}
								contentEditable={
									this.state.conversationEditIndex === index
								}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();

										this.updateConversationName(
											e.target.innerText
										);

										this.setState({
											conversationEditIndex: null,
										});
									}
								}}>
								{conversation.name}
							</span>
							{!conversation.welcomeMessage && (
								<div className='date-privacy'>
									<div className='tooltip-container'>
										<FontAwesomeIcon
											className={`icon privacy-icon ${
												conversation.public &&
												'privacy-icon-public'
											}`}
											onClick={
												conversation.pinned
													? this.toggleSinglePin.bind(
															this,
															index
													  )
													: this.toggleSinglePrivacy.bind(
															this,
															index
													  )
											}
											icon={
												conversation.pinned
													? faThumbtack
													: conversation.public
													? faLockOpen
													: faLock
											}
										/>
										<Tooltip
											message={
												conversation.pinned
													? 'Unpin this conversation'
													: conversation.public
													? 'Make this conversation private'
													: `Make this conversation public`
											}
										/>
									</div>
									<span className='date'>
										{conversation.createdAt}
									</span>
								</div>
							)}
						</div>
						<div className='actions'>
							<FontAwesomeIcon
								icon={faEdit}
								onClick={() => {
									this.setState(
										{
											conversationEditIndex: index,
										},
										() => {
											this.selectedNameRef.current.focus();
										}
									);
								}}
								className={`icon edit-icon ${
									active && 'icon-visible'
								}`}
							/>
							<FontAwesomeIcon
								icon={faArrowRight}
								className='icon'
							/>
						</div>
					</div>
				);
			});

		const messagesMap = this.state.conversations?.[
			this.state.selectedConversation
		]?.messages?.map((message, index) => {
			const md = MarkdownIt({
				linkify: true,
				breaks: true,
			})
				.use(mdKatex)
				.use(mdHighlight);

			const rendered = md.render(message.content);

			return (
				<div className='message-container' key={index}>
					<div className={`message message-${message.role}`}>
						<div className='message-content'>
							{message.role === 'user' ? (
								// <img
								// 	src={user.avatarURL}
								// 	width={25}
								// 	height={25}
								// 	className='message-icon'
								// />
								<FontAwesomeIcon icon={faUser} />
							) : (
								<img
									src={Logo}
									width={25}
									height={25}
									className='message-icon-bot'
								/>
							)}
							<div className='details'>
								<div
									className='content'
									dangerouslySetInnerHTML={{
										__html: rendered,
									}}
								/>
								<span className='date'>{message.date}</span>
							</div>
						</div>
						{message.role === 'assistant' &&
							!this.state.conversations?.[
								this.state.selectedConversation
							].welcomeMessage && (
								<div className='message-actions'>
									{message.role === 'assistant' && (
										<div className='tooltip-container'>
											<FontAwesomeIcon
												icon={faRotateRight}
												onClick={this.regenerateResponse.bind(
													this
												)}
												className='icon'
											/>
											<Tooltip
												rightAnchor
												message='Regenerate response'
											/>
										</div>
									)}
									{this.props.user.tier !== 'Free' && (
										<div className='tooltip-container'>
											<Gradient
												gradients={gradients}
												property='text'
												element='i'
												className='fa-solid fa-pen-nib icon'
												angle='90deg'
												onClick={this.rewriteResponse.bind(
													this,
													index
												)}
											/>
											<Tooltip
												rightAnchor
												message='Rewrite response'
											/>
										</div>
									)}
									<div className='tooltip-container'>
										<FontAwesomeIcon
											icon={faClone}
											className='icon'
											onClick={() => {
												utils.copyToClipboard(
													message.content
												);
											}}
										/>
										<Tooltip
											rightAnchor
											message='Copy response'
										/>
									</div>
								</div>
							)}
					</div>
					{this.state.showSimilarIndex === index &&
						this.state.similarAnswers?.map((answer, index) => {
							const md = MarkdownIt({
								linkify: true,
								breaks: true,
							})
								.use(mdKatex)
								.use(mdHighlight);

							const rendered = md.render(answer.content);

							return (
								<div
									className={`message message-assistant message-similar`}>
									<div className='message-content'>
										<img
											src={Logo}
											width={25}
											height={25}
											className='message-icon-bot'
										/>
										<div className='details'>
											<div
												className='content'
												dangerouslySetInnerHTML={{
													__html: rendered,
												}}
											/>
											<span className='date'>
												{answer.date}
											</span>
										</div>
									</div>
									<div className='message-actions'>
										<div className='similar-result'>
											Similar result
										</div>
									</div>
								</div>
							);
						})}
					{/* {message.role === 'assistant' &&
						!this.state.conversations?.[
							this.state.selectedConversation
						]?.welcomeMessage &&
						index ===
							this.state.conversations?.[
								this.state.selectedConversation
							]?.messages?.length -
								1 && (
							<div
								className='message-pulldown'
								onClick={async () => {
									this.setState(
										{
											showSimilarIndex: this.state
												.showSimilarIndex
												? null
												: index,
										},
										async () => {
											await this.getSimilarAnswers();
										}
									);
								}}>
								{this.state.showSimilarIndex
									? 'Hide similar answers'
									: `See similar answers`}
							</div>
						)} */}
				</div>
			);
		});

		return (
			<div className='chat'>
				<div className='conversations-container'>
					<div className='conversations-header'>
						{this.props.user.tier === 'Unlimited' && (
							<Gradient
								gradients={gradients}
								property='text'
								element='span'
								angle='90deg'>
								ChatGPT V4.0
							</Gradient>
						)}
						<div className='stream-responses-container'>
							<div className='tooltip-container'>
								<FontAwesomeIcon
									className='tooltip-icon'
									icon={faInfoCircle}
								/>
								<Tooltip message='Gradually send responses as they are generated.' />
							</div>
							<Toggle
								checked={this.state.streamResponses}
								label='Stream Responses'
								onChange={this.toggleStreamResponses.bind(this)}
							/>
						</div>
					</div>
					<div className='conversations'>{conversationsMap}</div>
					<div className='controls'>
						{this.state.selectedConversations.length > 0 && (
							<div className='select-actions'>
								<FontAwesomeIcon
									icon={faTrashCan}
									onClick={this.deleteConversations.bind(
										this
									)}
									className='icon delete-icon'
								/>
								<div className='tooltip-container'>
									<FontAwesomeIcon
										className='icon privacy-icon'
										onClick={this.togglePrivacy.bind(this)}
										icon={faLock}
									/>
									<Tooltip message='Make this conversation public' />
								</div>
								<FontAwesomeIcon
									icon={faThumbtack}
									onClick={this.togglePin.bind(this)}
									className='icon pin-icon'
								/>
							</div>
						)}
						{this.state.conversationsError && (
							<div className='error'>
								{this.state.conversationsError}
							</div>
						)}
						<Button
							label='New Conversation'
							icon={faComment}
							onClick={this.addConversation.bind(this)}
						/>
					</div>
				</div>
				<div className='messaging'>
					{this.state.conversations?.[
						this.state.selectedConversation
					] && (
						<div
							className={`messages ${
								(!messagesMap || messagesMap.length === 0) &&
								'messages-empty'
							}`}
							ref={this.messagesRef}>
							{!messagesMap || messagesMap.length === 0 ? (
								<div className='no-messages'>
									<span>
										This conversation has no messages!
									</span>
									<span>
										Type a message to start chatting.
									</span>
								</div>
							) : (
								messagesMap
							)}
							{this.state.loadingResponse && (
								<div className='message-container'>
									<div
										className={`message message-assistant`}>
										<div className='message-content'>
											<img
												src={Logo}
												width={25}
												height={25}
												className='message-icon-bot'
											/>
											<div className='details'>
												<span className='content'>
													<div className='response-loader'>
														<FontAwesomeIcon
															icon={faSpinner}
															spin
														/>{' '}
														Generating response...
													</div>
												</span>
												<span className='date'>
													{new Date().toLocaleDateString()}
												</span>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					)}
					{this.state.conversations?.[
						this.state.selectedConversation
					] &&
						!this.state.conversations?.[
							this.state.selectedConversation
						].welcomeMessage && (
							<div className='controls'>
								<Input
									placeholder='Type a mesage or a question...'
									className='message-input'
									value={this.state.message}
									onChange={(message) => {
										this.setState({ message });
									}}
								/>
								<Button
									label='Send'
									big
									icon={faPaperPlane}
									cta
									onClick={this.sendMessage.bind(this)}
								/>
							</div>
						)}
				</div>
			</div>
		);
	}
}

export default Chat;
