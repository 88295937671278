import axios from 'axios';

const baseUrl = 'https://us-central1-thoughtlink-app.cloudfunctions.net/app';

async function createPaymentRequest({ sourceId, email, password, plan }) {
	try {
		const response = await axios.post(`${baseUrl}/create-payment`, {
			sourceId,
			email,
			password,
			plan,
		});

		return response.data;
	} catch (err) {
		return;
	}
}

async function cancelSubscription({ email, password }) {
	try {
		const response = await axios.post(`${baseUrl}/cancel-subscription`, {
			email,
			password,
		});

		return response.data;
	} catch (err) {
		return;
	}
}

export default { createPaymentRequest, cancelSubscription };
