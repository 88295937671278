import Label from 'Components/shared/Label/Label';
import { Gradient } from 'react-gradient';
import './About.scss';
import {
	faCode,
	faGraduationCap,
	faPersonCane,
	faRightLong,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SizeGraphicGPT from 'assets/images/SizeGraphicGPT.png';
import ScreenShot1 from 'assets/images/ScreenShot1.png';
import ScreenShot2 from 'assets/images/ScreenShot2.png';
import ScreenShot3 from 'assets/images/ScreenShot3.png';
import ScreenShot4 from 'assets/images/ScreenShot4.png';

const gradients = [['#9382ff', '#ba9cff']];

function About() {
	return (
		<div className='about'>
			<div className='section'>
				<Gradient
					gradients={gradients}
					property='text'
					element='h1'
					className='title'
					angle='90deg'>
					ThoughtLink is made for <i>everyone</i>.
				</Gradient>
				<span className='text'>
					ThoughtLink is made with everyone in mind. From developers,
					to students, to your tech-illiterate parents.
				</span>
				<div className='made-for-list'>
					<FontAwesomeIcon size='xl' icon={faCode} />
					<FontAwesomeIcon size='xl' icon={faRightLong} />
					<FontAwesomeIcon size='xl' icon={faGraduationCap} />
					<FontAwesomeIcon size='xl' icon={faRightLong} />
					<FontAwesomeIcon size='xl' icon={faPersonCane} />
				</div>
			</div>
			<div className='section'>
				<Label label='Why ThoughtLink?' />
				<span className='text'>
					ThoughtLink expands the capabilities of current AI chatbots
					with extra features such as rewriting response, long-term
					memory, and personality customization.
				</span>
				<h1>ThoughtLink</h1>
				<span className='text'>
					Long-term memory allows ThoughtLink to remember what you
					have said between conversations and at any point in time.
					This means if you say something and ask about it a year
					later, ThoughLink will know about it!
				</span>
				<div className='image-container'>
					<img
						src={ScreenShot1}
						alt='ScreenShot1'
						className='screenshot'
					/>
					<span className='image-description'>Conversation 1</span>
				</div>
				<div className='image-container'>
					<img
						src={ScreenShot2}
						alt='ScreenShot2'
						className='screenshot'
					/>
					<span className='image-description'>Conversation 2</span>
				</div>
				<h1>OpenAI ChatGPT</h1>
				<span className='text'>
					OpenAI's ChatGPT does not have long-term memory, and is
					unable to remember what you have said between conversations.
				</span>
				<div className='image-container'>
					<img
						src={ScreenShot3}
						alt='ScreenShot3'
						className='screenshot'
					/>
					<span className='image-description'>Conversation 1</span>
				</div>
				<div className='image-container'>
					<img
						src={ScreenShot4}
						alt='ScreenShot4'
						className='screenshot'
					/>
					<span className='image-description'>Conversation 2</span>
				</div>
				<span className='text'>
					ThoughtLink also provides access to premium generative AI
					technology, such as ChatGPT 4, but at a lower cost, and with
					more features.
				</span>
			</div>
		</div>
	);
}

export default About;
