import { Component, createRef } from 'react';
import './Input.scss';

class Input extends Component {
	constructor(props) {
		super();

		this.state = {
			value: props.value,
			error: !props.valie,
		};
		this.inputRef = createRef();
	}

	handleChange(e) {
		let value = e.target.value;
		const { onChange, required } = this.props;

		if (!value) {
			value = '';

			if (required) {
				this.setState({
					error: true,
					value,
				});
			}
		} else {
			if (required) {
				this.setState({
					error: false,
					value,
				});
			}
		}

		this.setState({
			value,
		});

		if (onChange) {
			onChange(value);
		}
	}

	render() {
		const {
			required,
			submitted,
			onChange,
			label,
			className,
			error: propsError,
			...rest
		} = this.props;
		const { error, value } = this.state;

		if (required && !onChange && submitted) {
			const isError = !value;

			if (error !== isError) {
				this.setState({
					error: isError,
				});
			}
		}

		return (
			<div
				className={`input-container ${
					label && 'input-has-label'
				} ${className}`}>
				<span className='input-label'>{label}</span>
				<input
					ref={this.inputRef}
					{...rest}
					onChange={this.handleChange.bind(this)}
					className={`input ${
						submitted && (error || propsError) && 'input-error'
					} input-${'default'}`}
				/>
			</div>
		);
	}
}

export default Input;
